@import '../../Variables.module'

.reactions--container
    position: absolute
    bottom: -$pos15
    right: $pos20
    display: flex
    align-items: center
    gap: $gap5
    background: $light-brown
    padding: $pd5 $pd10
    border-radius: $br16
    overflow: hidden
    transition: .2s ease-in-out

    .reaction
        display: flex
        align-items: center
        gap: $gap5
        user-select: none
        cursor: pointer

        &.reaction--count
            font-size: $fz14
            line-height: $lh24
            background: $brown
            border-radius: $br16
            padding: 0 $pd10