@import '../../Variables.module'

.header
    display: flex
    gap: $gap40
    align-items: center
    justify-content: space-between
    margin-bottom: $mg40

    .logo
        display: flex
        align-items: center
        gap: $gap16

    .logo--image
        width: $wl68
        height: $hl68

    .logo--title
        font-family: $Coiny
        font-size: $fz42
        line-height: $lh48