// colors
$textColor: #fafaf9
$bodyColor: #292524
$blue: #3b82f6
$green: #16a34a
$dark-green: #117937
$red: #ef4444
$dark-red: #bd3838
$yellow: #eab308
$pink: #db2777
$turquoise: #14b8a6
$orange: #f97316
$purple: #8b5cf6
$brown: #44403c
$light-brown: #78716c
$dark-light-brown: #58534f

$gradient: linear-gradient(90deg, $red 0%, $purple 100%)

// page sizes
$wp1500: 150rem

// font families
$Coiny: 'Coiny', sans-serif
$Sono: 'Sono', sans-serif

// font sizes
$fz12: 1.2rem
$fz14: 1.4rem
$fz16: 1.6rem
$fz20: 2rem
$fz42: 4.2rem

// line heights
$lh18: 1.8rem
$lh24: 2.4rem
$lh32: 3.2rem
$lh48: 4.8rem

// font weights
$fw400: 400
$fw600: 600

// border radius
$br6: .6rem
$br16: 1.6rem
$br100: 10rem

// flex and grid gaps
$gap5: .5rem
$gap16: 1.6rem
$gap20: 2rem
$gap40: 4rem

// logo sizes
$wl68: 6.8rem
$hl68: 6.8rem

// paddings
$pd5: .5rem
$pd10: 1rem
$pd14: 1.4rem
$pd20: 2rem
$pd24: 2.4rem
$pd32: 3.2rem
$pd48: 4.8rem
$pd56: 5.6rem

// margins
$mg10: 1rem
$mg20: 2rem
$mg40: 4rem

// opacities
$op04: .4

// positions
$pos15: 1.5rem
$pos20: 2rem