@import '../../Variables.module'

.not-found
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

    .message
        font-size: $fz42
        line-height: $hl68
        text-align: center