@import '../../Variables.module'

.flex--cards
    display: grid
    gap: $gap20

    .fact--card
        position: relative
        height: max-content
        background: $brown
        padding: $pd20
        border-radius: 0 $br16 0 $br16

        .message
            font-size: $fz20
            line-height: $lh32

        .card--footer
            display: flex
            gap: $gap16
            margin-top: $mg10

            .source-link
                max-width: fit-content
                color: $textColor
                background: $light-brown
                font-size: $fz12
                line-height: $lh18
                font-weight: $fw600
                padding: $pd5
                border-radius: $br6
                text-decoration: none

            .date
                max-width: fit-content
                background: $light-brown
                font-size: $fz12
                line-height: $lh18
                font-weight: $fw600
                padding: $pd5
                border-radius: $br6