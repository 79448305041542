@import '../../Variables.module'
    
.notification--box
    display: flex
    align-items: center
    gap: $gap16
    position: fixed
    top: $pos20
    right: -100%
    max-width: 35rem
    font-size: $fz14
    line-height: $lh18
    padding: $pd14
    border-radius: $br6
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, .2)
    transition: .5s ease-in-out

    &.visible
        right: $pos20

    &.red
        background: $red
        border-left: .6rem solid $dark-red

    &.green
        background: $green
        border-left: .6rem solid $dark-green

    &.light-brown
        background: $light-brown
        border-left: .6rem solid $dark-light-brown

    .close--button
        cursor: pointer