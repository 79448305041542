@import '../../Variables.module'

.fact--form
    display: grid
    grid-template-columns: calc(35% - $gap16) calc(35% - $gap16) calc(20% - $gap16) 10%
    gap: $gap16
    background: $brown
    padding: $pd10 $pd24
    margin-bottom: $mg20
    border-radius: $br16

    .fact--form-input
        position: relative

        input
            width: 100%

            &::placeholder
                font-size: $fz16
                line-height: $lh24

        .fact--input
            padding-right: $pd56

        .fact--form-length
            position: absolute
            top: 50%
            right: $pos15
            font-size: $fz20
            font-weight: $fw600
            transform: translateY(-50%)