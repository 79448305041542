@import '../../Variables.module'

.categories--list
    height: fit-content
    background: $brown
    padding: $pd20
    border-radius: $br16

    .list--title
        font-size: $fz20
        line-height: $lh32
        margin-bottom: $mg10

        &:hover
            background: $brown

    .list--options
        display: flex
        flex-direction: column
        gap: $gap5

        .list--item
            text-transform: capitalize
            padding: $pd5 $pd10
            border-radius: $br6
            cursor: pointer
            transition: .2s ease-in-out

            &:hover, &.active
                background: $light-brown
    