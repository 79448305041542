@import '../../Variables.module'

.dropdown--input
    padding-right: $pd56

.dropdown--trigger
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    top: 50%
    right: $pos15
    width: 3rem
    height: 3rem
    background: $brown
    border-radius: $br100
    transform: translateY(-50%)
    user-select: none
    cursor: pointer

    & > span
        font-size: $fz20
        line-height: $lh24

.dropdown--list
    opacity: 0
    visibility: hidden
    position: absolute
    top: calc(100% + $pos15)
    width: 100%
    background: $light-brown
    padding: $pd20 $pd10
    border-radius: $br16
    box-sizing: border-box
    transition: all .2s ease-in-out
    z-index: 99999

    &.visible
        opacity: 1
        visibility: visible

    .list--options
        display: flex
        flex-direction: column

        .list--item
            padding: $pd5 $pd10
            border-radius: $br6
            cursor: pointer
            transition: .2s ease-in-out

            &:hover
                background: $brown