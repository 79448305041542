// import fonts
@import 'https://fonts.googleapis.com/css2?family=Coiny&family=Sono:wght@200;300;400;500;600;700;800&display=swap'

// import other sass files
@import 'Variables.module'

// globals
*
    margin: 0
    padding: 0

html
    font-size: 10px
    overflow-x: hidden

body
    color: $textColor
    background: $bodyColor
    font-family: $Sono
    font-size: $fz16
    line-height: $lh24
    font-weight: $fw400

ul, li, ol
    list-style-type: none

input, textarea, select
    color: $textColor
    background: $light-brown
    font-family: $Sono
    font-size: $fz20
    border: 0
    outline: 0
    padding: $pd14 $pd20
    border-radius: $br16
    box-sizing: border-box

    &::placeholder
        color: $textColor
        opacity: $op04

.main--container
    position: relative

.page--size
    max-width: $wp1500
    margin: 0 auto

.page--padding
    padding: $pd48 $pd20

.primary--btn
    color: $textColor
    background: $gradient
    font-family: $Coiny
    font-size: $fz20
    line-height: $lh32
    border: 0
    outline: 0
    border-radius: $br100
    padding: $pd10 $pd32
    cursor: pointer

.facts--container
    display: grid
    grid-template-columns: 30rem auto
    gap: $gap20